<template>
  <div class="w-full">

    <h2 class="font-bold sticky top-0 bg-gray-100 py-2 z-50">
      <div class="text-lg lg:text-2xl">{{material.title}}</div>
      <div class="text-sm">
        <span class="">Source: </span><span class="text-green-700 font-normal">{{ material.source }}</span>
      </div>
    </h2>

    <!-- If material type is video (typeId=1)   -->
    <!-- Video container   -->
    <div class="relative py-5" v-if="material.typeId == 1">
      <overly v-if="videoLoading">
        <spinner></spinner>
      </overly>

      <div class="bg-gray-100 " style="box-shadow:inset 0 0 60px #ccc">

        <iframe width="100%" height="360"
                :src="frameLink"
                :title="material.title"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                @load="videoLoaded"
        >
        </iframe>

      </div>

    </div>
    <!-- End of video container   -->


    <div class="mb-5 mt-2 ck-content">
      <div v-html="material.text.replace(/\n/g, '<br>')"></div>
    </div>


<!--    {{ material }}-->


  </div>
</template>

<script>
import Spinner from "../../../components/spinner";
import Overly from "../../../components/overly";
export default {
  name: "view-materials",
  components: {Overly, Spinner},
  props: {
    material: { type: Object, default: { } }
  },
  data(){
    return {
      videoLoading: true
    }
  },
  created() {

  },
  methods: {
    videoLoaded(){
      this.videoLoading = false;
    }
  },
  watch: {
     'material'(){
       this.videoLoading = true;
     }
  },
  computed: {
    frameLink(){


      switch (this.material.source) {

        case 'youtube':
          const ytvId = (new URL(  this.material.link )).searchParams.get('v');
          return `https://www.youtube.com/embed/${ytvId}`;
        case 'vimeo': {

          const vimeo = (new URL(  this.material.link ));
          if( vimeo.hostname == "player.vimeo.com" && String(vimeo.pathname).search(/^(\/|)video\/[0-9]+/) > -1 ) {
            return this.material.link;
          }

          if( vimeo.hostname == "vimeo.com" && String(vimeo.pathname).search(/^(\/|)[0-9]+(\/|)/) > -1 ) {
            const segments = String( vimeo.pathname ).replace(/^\//, '').split('/');

            if( segments[0] ){
              return `https://player.vimeo.com/video/${segments[0]}`;
            }
          }

          return '';
        }
        default:
            return ''
      }

    }
  }

}
</script>

<style scoped>

</style>